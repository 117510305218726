@import "../../_globalColor";

.main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 4rem;
}

.greet-main {
  width: 90%;
  padding: 20px 10px;
  margin: 0px auto;
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.greeting-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.greeting-text-div {
  width: 50%; /* Take up half the page */
  text-align: left;
  padding-right: 20px; /* Add space between text and image */
}

.subTitle {
  color: $subTitle !important;
}

.button-greeting-div {
  display: flex;
  margin-top: 20px;
  gap: 15px; /* Add space between the buttons */
}

.download-link-button {
  text-decoration: none;
}

.greeting-text {
  font-size: 60px; /* Reduce size slightly */
  line-height: 1.1;
  color: $textColor !important;
}

.greeting-text-p {
  font-size: 24px; /* Adjust the font size for a cleaner look */
  line-height: 36px;
  margin-bottom: 20px; /* Add spacing between text and icons */
}

.greeting-image-div > img {
  max-width: 100%;
  height: auto;
}

.social-media {
  display: flex;
  gap: 15px; /* Add spacing between social icons */
  margin-top: 20px;
}

.wave-emoji {
  animation-duration: 1.8s;
  animation-iteration-count: infinite;
  animation-name: wave;
  display: inline-block;
  transform-origin: 70% 70%;
}

.button {
  background-color: #e74c3c;
  padding: 10px 20px;
  border-radius: 6px; /* Add rounded corners */
  font-size: 16px;
  transition: all 0.3s ease;
}

.button:hover {
  background-color: #c0392b; /* Add a hover effect */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

@keyframes wave {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(-10deg);
  }
  20% {
    transform: rotate(12deg);
  }
  30% {
    transform: rotate(-10deg);
  }
  40% {
    transform: rotate(9deg);
  }
  50% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

/* Media Query */
@media (max-width: 1380px) {
  .greeting-text {
    font-size: 50px;
  }
  .greeting-text-p {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .greet-main {
    flex-direction: column;
    align-items: center;
  }
  .greeting-text-div {
    width: 100%;
    text-align: center;
  }
  .button-greeting-div {
    justify-content: space-around;
  }
  .greeting-text {
    font-size: 30px;
    text-align: center;
  }
  .greeting-text-p {
    font-size: 16px;
    line-height: normal;
    text-align: center;
  }
  .greeting-image-div {
    height: 256px;
  }
}

@media (max-width: 320px) {
  .main {
    width: auto;
  }
}
